<template>
	<div class="phi-target"></div>
</template>

<script>
export default {
	name: 'phi-target',

	props: {
		/*
		Este componente recibe en su value un objeto de tipo "target",
		y reubica el marker en el mapa cuando detecta cambios en la posicion

		{
			id: "xyz",
			name: "nombre",
			icon: "xxxx",
			position: {
				latitude: xxxx,
				longitude: xxxx
			}
		}
		*/
		value: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			map: this.$parent.$mapObject,
			marker: null,
			isOpen: false,
			rotation: 0,
			history: [this.value.position]
		}
	},

	mounted() {
		this.initializeAnimateFunction();
		this.initializeMarker();
	},

	watch: {
		'value.position': {
			deep: true,
			handler(newValue, oldValue) {
				this.moveTo(newValue, oldValue);
			}
		}
	},

	methods: {
		moveTo(newPosition, oldPosition) {
			let gCoordsNew      = new google.maps.LatLng(newPosition.latitude, newPosition.longitude);
			let gCoordsPrevious = oldPosition ? new google.maps.LatLng(oldPosition.latitude, oldPosition.longitude) : gCoordsNew;

			this.history.push(newPosition);

			this.rotation = google.maps.geometry.spherical.computeHeading(gCoordsPrevious, gCoordsNew);

			// this.marker.setPosition(gCoordsNew);
			this.marker.animateTo(gCoordsNew, null, gCoordsPrevious);
		},

		initializeMarker() {
			if (this.marker != null) {
				return;
			}

			let markerIcon = this.value.icon ? {
				url: this.value.icon,
				// This marker is 20 pixels wide by 32 pixels high.
				// size: new google.maps.Size(20, 32),
				// The origin for this image is (0, 0).
				origin: new google.maps.Point(0, 0)
				// The anchor for this image is the base of the flagpole at (0, 32).
				// anchor: new google.maps.Point(0, 32)
			} : null;

			this.marker = new google.maps.Marker({
				position: new google.maps.LatLng(this.value.position.latitude, this.value.position.longitude),
				map: this.map,
				icon: markerIcon,
				title: this.value.name,
				draggable: false
			});


			let infoWindow = new google.maps.InfoWindow({
				content: this.value.name  // can contain HTML
			});

			this.marker.addListener('click', () => {
				this.isOpen = !this.isOpen;
				if (this.isOpen) {
					infoWindow.open(this.map, this.marker);
				} else {
					infoWindow.close(this.map, this.marker);
				}
			});
		},

		initializeAnimateFunction() {
			// https://robsite.net/google-maps-animated-marker-move/
			if (!google || typeof google.maps.Marker.prototype.animateTo != "undefined") {
				return;
			}

			let defaultOptions = {
				duration: 3000,
				easing: 'linear',
				complete: null
			};

			google.maps.Marker.prototype.animateTo = function(newPosition, options, lastCoords) {
				options = options || {};

				for (let key in defaultOptions) {
					options[key] = options[key] || defaultOptions[key];
				}

				if (options.easing != 'linear') {
					if (typeof jQuery == 'undefined' || !jQuery.easing[options.easing]) {
						throw '"' + options.easing + '" easing function doesn\'t exist. Include jQuery and/or the jQuery easing plugin and use the right function name.';
						return;
					}
				}

				window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
				window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

				this.AT_startPosition_lat = lastCoords.lat();
				this.AT_startPosition_lng = lastCoords.lng();
				var newPosition_lat       = newPosition.lat();
				var newPosition_lng       = newPosition.lng();

				// crossing the 180° meridian and going the long way around the earth?
				if (Math.abs(newPosition_lng - this.AT_startPosition_lng) > 180) {
					if (newPosition_lng > this.AT_startPosition_lng) {
						newPosition_lng -= 360;
					} else {
						newPosition_lng += 360;
					}
				}

				var animateStep = (marker, startTime) => {
					var ellapsedTime = (new Date()).getTime() - startTime;
					var durationRatio = ellapsedTime / options.duration; // 0 - 1
					var easingDurationRatio = durationRatio;

					// use jQuery easing if it's not linear
					if (options.easing !== 'linear') {
						easingDurationRatio = jQuery.easing[options.easing](durationRatio, ellapsedTime, 0, 1, options.duration);
					}

					if (durationRatio < 1) {
						var deltaPosition = new google.maps.LatLng( marker.AT_startPosition_lat + (newPosition_lat - marker.AT_startPosition_lat)*easingDurationRatio, marker.AT_startPosition_lng + (newPosition_lng - marker.AT_startPosition_lng)*easingDurationRatio);
						marker.setPosition(deltaPosition);
						// this.map.setCenter(deltaPosition);

						// use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
						if (window.requestAnimationFrame) {
							marker.AT_animationHandler = window.requestAnimationFrame(function() {animateStep(marker, startTime)});
						} else {
							marker.AT_animationHandler = setTimeout(function() {animateStep(marker, startTime)}, 17);
						}

					} else {
						marker.setPosition(newPosition);
						if (typeof options.complete === 'function') {
							options.complete();
						}
					}
				}

				// stop possibly running animation
				if (window.cancelAnimationFrame) {
					window.cancelAnimationFrame(this.AT_animationHandler);
				} else {
					clearTimeout(this.AT_animationHandler);
				}

				animateStep(this, (new Date()).getTime());
			}

		}
	}
}
</script>

<style lang="scss">
.phi-target {
	position: absolute;
	width: 200px;
	height: 200px;
	background: transparent;
	z-index: 999;
}
</style>

<!--https://github.com/terikon/marker-animate-unobtrusive#SlidingMarker.options.animateFunctionAdapter-->
